import { inject, Injectable } from '@angular/core';
import { ApplicationResource } from '@mkp/application/data-access';
import {
  ApplicationCounts,
  ApplicationStatus,
  ApplicationTab,
  getTab,
} from '@mkp/application/models';
import { forkJoin, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApplicationCountsService {
  private readonly applicationResource = inject(ApplicationResource);

  fetchApplicationCounts(
    statuses: ApplicationStatus[],
    vacancyId: string
  ): Observable<ApplicationCounts> {
    const tabToStatusIds = getTabToStatusIdsMap(statuses);

    return forkJoin(
      Object.entries(tabToStatusIds).reduce(
        (acc, [tab, statusIds]) => ({
          ...acc,
          [tab]: this.applicationResource.getCountByApplicationStatusIds(statusIds, vacancyId),
        }),
        {} as Record<ApplicationTab, Observable<number>>
      )
    );
  }
}

const getTabToStatusIdsMap = (statuses: ApplicationStatus[]) =>
  statuses
    .map(({ step, id }) => ({ tab: getTab(step), id }))
    .reduce(
      (acc, { tab, id }) => ({ ...acc, [tab]: [...(acc[tab] ?? []), id] }),
      {} as Record<ApplicationTab, string[]>
    );
