import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { Environment } from './environment.model';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const packageJson = require('../../../../package.json');

export const environment: Environment = {
  production: false,
  name: 'qa',
  version: packageJson.version,
  hash: packageJson.hash,
  ngrxDevTools: [StoreDevtoolsModule.instrument({ maxAge: 100, connectInZone: true })],
  apolloDevTools: false,
  url: 'https://qa.hire.jobcloud.ai',
  translationHash: new Date().getTime().toString(),
  network: {
    jobs: 'https://stage.jobs.ch',
    jobcloud: 'https://www.stage.jobcloud.ch',
    js24: 'https://stage.jobscout24.ch',
    jobup: 'https://www.stage.jobup.ch',
  },
  api: {
    core: 'https://api.stage.jobcloud.ai',
    ats: 'https://api.stage.jobcloud.ai/ats',
    internalATS: 'https://ats.stage.jobcloud.ai',
    metadata: 'https://metadata-api-stage.jobcloud.services/api/v1/meta',
    media: 'https://media.stage.jobcloud.ai/media',
    ecom: 'https://ecommerce.stage.jobcloud.ai/shop-api',
    middleware: 'https://middleware.qa.jobcloud.ai',
  },
  auth: {
    clientId: 'jgDFsK2aFjYjJNjsiwYhdlYn5kf677Vz',
    domain: 'auth.stage.jobcloud.ai',
    audience: 'marketplace-apis',
    scope: 'openid profile email offline_access',
    connection: 'Username-Password-Authentication',
    redirectUri: 'https://qa.hire.jobcloud.ai',
    responseType: 'code',
    skipRedirectCallback: true,
    useRefreshTokens: true,
  },
  sentry: {
    target: 'qa.hire.jobcloud.ai',
    tracesSampleRate: 0.2,
  },
  // TODO: remove when we no longer need these ids - CDP-1098
  createdByIdsForImportedJobAds: [
    '51fc2c5e-7a77-4cf6-aa03-b6a8758b3a50',
    '857ee77d-9836-4beb-9cec-5dab4c46a944',
    'ba73079d-c6f1-4bcd-986f-ef089df6cfe8',
  ],
};

export const auth = environment.auth;
export const createdByIdsForImportedJobAds = environment.createdByIdsForImportedJobAds;
