import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import {
  Application,
  ApplicationStep,
  ApplicationTab,
  ApplicationUpdatePayload,
  EmailContent,
} from '@mkp/application/models';
import { BaseHttpResource, CoreListEnvelop, QueryParams } from '@mkp/shared/data-access';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import { ApplicationDto } from './application.dto';
import { mapApplicationDtoToModel } from './application.mapper';

const BATCH = 10;

export interface FetchApplicationPayload {
  statusIds: string[];
  vacancyId: string;
  offset: number;
  limit: number;
  tab: ApplicationTab;
}

@Injectable({
  providedIn: 'root',
})
export class ApplicationResource extends BaseHttpResource<
  ApplicationDto,
  CoreListEnvelop<ApplicationDto>,
  Application
> {
  protected override readonly apiUrl = environment.api.internalATS;

  constructor() {
    super('application');
  }

  fetchApplicationsByTab({
    statusIds,
    vacancyId,
    offset,
    limit,
    tab,
  }: FetchApplicationPayload): Observable<Application[]> {
    const params: QueryParams = {
      filter: getFilter({ statusIds, vacancyId }),
      offset,
      limit,
    };

    if (tab === ApplicationStep.Unsuitable) {
      params['sort'] = 'applicationStatus.step=asc;createdAt=desc';
    }

    return this.http
      .get<CoreListEnvelop<ApplicationDto>>(getApplicationUrl(this.uri), { params })
      .pipe(
        map(({ _embedded: { results: applicationDtos } }) =>
          applicationDtos.map(mapApplicationDtoToModel)
        )
      );
  }

  getCountByApplicationStatusIds(statusIds: string[], vacancyId: string): Observable<number> {
    const params: QueryParams = {
      filter: getFilter({ statusIds, vacancyId }),
      offset: 0,
      limit: 0,
    };

    return this.http
      .get<CoreListEnvelop<Application>>(getApplicationUrl(this.uri), { params })
      .pipe(
        map((applicationsResponse) => applicationsResponse.totalCount),
        catchError(() => of(0))
      );
  }

  deleteApplication(applicationId: string): Observable<void> {
    return this.http.delete<void>(`${getApplicationUrl(this.uri)}/${applicationId}`);
  }

  sendDeclinationMail(applicationId: string, emailContent: EmailContent): Observable<void> {
    return this.http.post<void>(
      `${getApplicationUrl(this.uri)}/${applicationId}/send-decline-mail`,
      {
        subject: emailContent.subject,
        body: emailContent.message,
      }
    );
  }

  private getApplicationCount(vacancyId: string): Observable<number> {
    return this.getWithQuery({
      filter: `vacancy.id==${vacancyId}`,
      limit: 0,
    }).pipe(map(({ totalCount }) => totalCount));
  }

  getApplicationCounts(vacancyIds: string[]): Observable<Record<string, number>> {
    return forkJoin(
      vacancyIds.reduce(
        (acc, vacancyId) => ({ ...acc, [vacancyId]: this.getApplicationCount(vacancyId) }),
        {}
      )
    );
  }

  checkApplicationsPresence(vacancyId: string): Observable<boolean> {
    return this.getApplicationCount(vacancyId).pipe(map((totalNumber) => totalNumber > 0));
  }

  // TODO: Temporary used _version here.
  updateApplicationStatus(
    applicationId: string,
    applicationStatusId: string,
    _version: string
  ): Observable<Application> {
    return this.http.patch<Application>(`${getApplicationUrl(this.uri)}/${applicationId}`, {
      applicationStatusId,
      _version,
    });
  }

  getUpdateStatusBatches(
    applicationUpdatePayloads: ApplicationUpdatePayload[],
    applicationStatusId: string
  ): Observable<Application>[][] {
    return divideArrayIntoBatches(applicationUpdatePayloads, BATCH).map(
      (applicationUpdatePayloads) =>
        applicationUpdatePayloads.map(({ applicationId, _version }) =>
          this.http.patch<Application>(`${getApplicationUrl(this.uri)}/${applicationId}`, {
            applicationStatusId,
            _version,
          })
        )
    );
  }

  getTotalCountForVacancies(vacancyIds: string[]): Observable<number> {
    return this.getWithQuery({ filter: getVacanciesFilter(vacancyIds), limit: 0 }).pipe(
      map(({ totalCount }) => totalCount)
    );
  }
}

const getApplicationUrl = (uri: string): string => `${environment.api.internalATS}/${uri}`;
const getFilter = ({
  statusIds,
  vacancyId,
}: Pick<FetchApplicationPayload, 'statusIds' | 'vacancyId'>): string =>
  buildQueryString(statusIds, vacancyId);

const getVacanciesFilter = (vacancyIds: string[]): string =>
  vacancyIds.map((vacancyId) => `vacancy.id==${vacancyId}`).join(',');

const buildQueryString = (statusIds: string[], vacancyId: string): string => {
  const applicationFilter = statusIds
    .map((statusId) => `applicationStatus.id==${statusId}`)
    .join(',');
  return `${applicationFilter};vacancy.id==${vacancyId}`;
};

const divideArrayIntoBatches = <T>(array: T[], batch: number): T[][] =>
  array.reduce(
    (acc, element) => {
      const lastBatch = acc.at(-1) ?? [];
      const rest = acc.slice(0, -1);
      return [
        ...rest,
        ...(lastBatch.length === batch ? [lastBatch, [element]] : [[...lastBatch, element]]),
      ];
    },
    [[]] as T[][]
  );
